.marquee-word {
  margin-right: 30px;
  font-weight: 700;
  font-size: 3em;
}

@media only screen and (min-width: 1024px) {
  .marquee-container {
    padding-top: 50px;
    padding-bottom: 40px;
  }
  .marquee-word {
    font-size: 7em;
    margin-right: 1em;
  }
}
