.image-slider {
  position: relative;
  height: 250px;
  width: 100%;
  margin-left: 5%;
  margin-right: 3%;
  margin-top: 6%;
  margin-bottom: 5%;
  padding-top: 4%;
}

.slider-image {
  width: 90%;
  height: 90%;
  position: absolute;
  opacity: 0;
  transition: transform 1s ease-in-out;
}

.slider-image.active {
  opacity: 1;
  transform: translateY(0);
}

@media only screen and (min-width: 1024px) {
  .image-slider {
    position: relative;
    height: 370px !important;
    width: 660px !important;
    margin-left: 3%;
    margin-top: 5%;
  }
  .slider-image {
    padding-left: 3%;
    max-width: 80%;
    max-height: 90%;
    object-fit: cover;
    object-position: bottom;

    position: absolute;
    opacity: 0;
    transition: transform 1s ease-in-out;
  }
}
