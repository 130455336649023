body {
  /* background: #eee; */
  font-size: 14px;
  color: #000;
  margin: 0;
  padding: 0;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.swiper {
  width: 100%;
  height: 330px;
  margin: 20px auto;
}
.append-buttons {
  text-align: center;
  margin-top: 20px;
}

.append-buttons button {
  display: inline-block;
  cursor: pointer;
  border: 1px solid #007aff;
  color: #007aff;
  text-decoration: none;
  padding: 4px 10px;
  border-radius: 4px;
  margin: 0 10px;
  font-size: 13px;
}

.methodologies-main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 50px;
  padding-top: 50px;
}

.methodologies-main-section h1 {
  color: var(--white-color);
  font-weight: 700;
  font-size: 26px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--white-color);
  width: 280px;
  text-align: center;
  text-transform: uppercase;
}

.methodologies-container {
  background-image: url(../../../Assets/HomePage/MethodologiesAssets/MethodologiesBackground/MethodologiesBackground.png);
  margin-bottom: 100px;
  height: 100svh;
  background-size: 100% 100%;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.methodologies-cards {
  padding: 6%;
}

.methodologies-cards h1 {
  font-size: 16px;
  margin-bottom: 20px;
  text-align: left;
  padding-top: 2%;
}

.methodologies-cards p {
  font-size: 12px;
  margin-bottom: 22px;
  text-align: left;
  margin-top: 5px;
}

.methodologies-arrow {
  width: 40px;
}

.methodologies-cards {
  position: relative;
}

.methodologies-cartoons {
  position: absolute;
  bottom: 10px;
  right: 55px;
  transform: translate(35%, 5%) !important;
  z-index: 1;
  width: 150px;
}

.sociodrama-image {
  transform: translate(35%, 10%) !important;
}

.swiper-slide {
  align-items: normal;
  border-radius: 3px;
}

.custom-pagination {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.custom-pagination-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--white-color);
  margin: 5px 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: var(--white-color);
  }
}

.custom-pagination-dot.active {
  background-color: var(--white-color);
}

.custom-pagination-dot.active,
.custom-pagination-dot:hover {
  background-color: var(--white-color);
}

@media only screen and (min-width: 1024px) {
  .methodologies-main-section h1 {
    padding-top: 30px;
    font-size: 32px;
    width: 300px;
  }
  .swiper {
    width: 100%;
    height: 530px;
    margin: 20px auto;
    filter: drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.2));
  }
  .methodologies-container {
    height: 120vh;
  }
  .swiper-slide-active {
    width: 80%;
  }
  .mySwiper {
    width: 100%;
  }
  .methodologies-cartoons {
    position: absolute;
    bottom: 10px;
    right: 55px;
    transform: translate(10%, 0) !important;
    z-index: 1;
    width: 300px;
  }
  .sociodrama-image {
    position: absolute;
    bottom: 10px;
    right: 55px;
    transform: translate(10%, 15%) !important;
    z-index: 1;
    width: 300px;
  }
  .methodologies-cards h1 {
    font-size: 24px;
    margin-bottom: 30px;
    text-align: left;
  }
  .methodologies-cards p {
    font-size: 16px;
    margin-bottom: 32px;
    text-align: left;
  }
  .custom-pagination-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--white-color);
    margin: 20px 5px !important;
    cursor: pointer;
  }
  .swiper-slide {
    border-radius: 5px;
  }
}
