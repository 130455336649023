.solutions-main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 50px;
}

.solutions-main-container h1 {
  color: var(--white-color);
  font-weight: 700;
  font-size: 26px;
  padding-bottom: 15px;
  border-bottom: 2px solid var(--white-color);
  width: 280px;
  text-transform: uppercase;
  text-align: center;
}

.solutions-main-container p {
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  color: var(--white-color);
  line-height: 21px;
  width: 332px;
  margin-bottom: 50px;
}

@media only screen and (min-width: 1024px) {
  .solutions-main-container {
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 100px;
  }
  .solutions-main-container h1 {
    text-align: left;
    font-size: 32px;
    padding-bottom: 15px;
    margin-bottom: 18px;
  }
  .solutions-main-container p {
    font-weight: 400;
    font-size: 20px;
    text-align: left;
    color: var(--white-color);
    line-height: 30px;
    width: 650px;
    margin-bottom: 30px;
  }
}
