.landing-images {
  position: relative;
  z-index: 1;
  height: 330px;
}

.circle-image {
  /* transform: translate(95%, -50%) !important; */
  /* position: relative;
  width: 200px;
  top: 50%;
  left: 40%; 
  transform: translate(0, -50%); */

  position: absolute;
  width: 220px;
  height: 350px;
  top: 0 !important;
  right: 0;
  z-index: 0;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.landing-image {
  position: relative;
  width: 300px;
  transform: translate(10%, 80%) !important;
  height: 170px;
  margin-bottom: 50px;
  /* top: 60%; */
  padding-top: 35px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.methodologies-main-container {
  padding-left: 5%;
  padding-right: 5%;
  position: relative;
  /* overflow-x: hidden; */
  z-index: -1;
}

.methodologies-main-container h1 {
  color: var(--white-color);
  font-size: 30px;
  margin-bottom: 50px;
}

.p-section {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 20px;
  color: var(--white-color);
  /* font-size: 14px; */
  margin-bottom: 50px;
}

.methodologies-type-section {
  margin-bottom: -55px;
  z-index: 0;
  /* background-size: 100% 100%; */
}

.methodologies-type-section p {
  padding-bottom: 50px;
}

.imersao-methodology {
  background-image: url(../../Assets/MethodologiesPage/MethodologiesBackgrounds/ImersaoBackground.png);
  background-size: 100% 100% !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));

  /* width: 100%; */
}

.sociodrama-methodology {
  background-image: url(../../Assets/MethodologiesPage/MethodologiesBackgrounds/SociodramaBackground.png);
  background-size: 100% 100% !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.design-methodology {
  background-image: url(../../Assets/MethodologiesPage/MethodologiesBackgrounds/DesignThinkBackground.png);
  background-size: 100% 100% !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));

  padding-bottom: 55px;
  z-index: 0;
  position: relative;
}

.imersao-methodology-section {
  width: 300px;
  padding-top: 120px;
  margin-bottom: 30px;
  transform: translate(15%) !important;
}

.exception-image {
  padding-top: 60px;
  padding-bottom: 30px;
  width: 250px !important;
  transform: translate(35%, 10%) !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.imersao-methodology-text,
.sociodrama-methodology-text {
  padding: 5%;
  display: flex;
  flex-direction: column;
  gap: 30px;
  color: var(--white-color);
}

.methodologies-type-section h2 {
  padding-bottom: 5px;
  border-bottom: 2px solid var(--white-color);
  width: 180px;
}

.methodologies-main-container,
.methodologies-type-section {
  position: relative;
  z-index: -5;
  padding-top: 80px;
}

.sociodrama-methodology-text-exception {
  margin-bottom: -30px !important;
}

@media only screen and (min-width: 1024px) {
  .circle-image {
    position: absolute;
    width: 589px;
    height: 920px;
  }
  .landing-image {
    position: absolute;
    width: 666px;
    height: 343px;
    top: -40%;
    right: 2%;
  }
  .methodologies-main-container {
    z-index: 1 !important;
  }
  .methodologies-main-container h1 {
    margin-top: -200px;
    font-size: 60px;
  }
  .p-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    width: 45%;
  }
  .p-section p {
    font-size: 16px;
    line-height: 25px;
  }
  .imersao-methodology {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
  }
  .imersao-methodology-section {
    width: 420px;
    height: 430px;
  }
  .imersao-methodology-text {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }
  .imersao-methodology-text-h2 {
    font-size: 32px;
    margin-bottom: 30px;
    width: 300px !important;
  }
  .imersao-methodology-text p {
    width: 700px;
    font-size: 16px;
  }

  .sociodrama-methodology {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
  .exception-image {
    width: 450px !important;
    height: 550px;
    transform: translate(0) !important;
  }
  .sociodrama-methodology-text {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    text-align: left;
  }
  .sociodrama-methodology-text h2 {
    font-size: 32px;
    margin-bottom: 30px;
    width: 350px;
  }
  .sociodrama-methodology-text-exception p {
    width: 650px;
    font-size: 16px;
  }

  .story-methodology {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: row;
  }
  .exception-image {
    width: 450px !important;
    height: 550px;
  }
  .story-methodology-text {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }
  .sociodrama-methodology-text h2 {
    font-size: 32px;
    margin-bottom: 30px;
    width: 350px;
  }
  .sociodrama-methodology-text p {
    width: 650px;
    font-size: 16px;
  }

  .design-methodology {
    padding-top: 100px;
    padding-bottom: 100px;
    display: flex;
    align-items: flex-end;
    justify-content: space-around;
    flex-direction: row-reverse;
  }
  .exception-image {
    width: 450px !important;
    height: 550px;
  }
  .story-methodology-text {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
    text-align: right;
  }
  .sociodrama-methodology-text h2 {
    font-size: 32px;
    margin-bottom: 30px;
    width: 350px;
  }
  .sociodrama-methodology-text p {
    width: 650px;
    font-size: 16px;
  }
}
