body {
  overflow-x: hidden;
}

.who-main-container {
  padding-top: 150px;
  padding-left: 5%;
  padding-right: 5%;
  color: var(--white-color);
  margin-bottom: 50px;
  background-color: #055df2;
  padding-bottom: 50px;
}

.who-first-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 60px;
  flex-direction: column;
  margin-bottom: 120px;
}

.who-btn {
  width: auto;
  height: auto;
  font-family: Poppins;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 50px;
  font-size: 12px;
  border: 2px solid var(--white-color);
  background-color: transparent;
  color: var(--white-color);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* Hover state */

.who-btn:hover {
  background-color: var(--white-color);
  color: var(--main-blue);
}

/* Click state */

.who-btn:active {
  transform: scale(0.95);
}

.who-pics-section {
  padding-left: 1%;
  padding-right: 1%;
}

.her-pic {
  width: 100%;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.linkedin-who {
  width: 23px;
}

.who-name-linkedin {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  padding-left: 5%;
  transform: translateY(-120%);
  width: fit-content;
  height: auto;
}

.who-rita-pic {
  width: fit-content;
  /* transform: translateY(-70%) !important; */
  transform: translateX(65%) !important;
  width: 200px;
  z-index: -20 !important;
}

.who-ana-pic {
  width: 200px;
  z-index: 5 !important;
  margin-bottom: -80px;
}

.who-second-container {
  padding-top: 40px;
  padding-left: 5%;
  padding-right: 5%;
  color: var(--white-color);
  margin-bottom: 80px;
}

.translucido-h1 {
  border-bottom: 2px solid var(--white-color);
  width: 270px;
  padding-bottom: 15px;
  margin-bottom: 50px;
  text-transform: uppercase;
}

.translucido-p-section {
  margin-bottom: 90px;
}

.who-second-container p {
  margin-bottom: 30px;
}

.arrow-max {
  display: none;
}

.Translucido-pic {
  width: 100%;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.who-third-container {
  background-color: #4287fc;
  padding-top: 90px;
  padding-bottom: 60px;
  padding-left: 5%;
  padding-right: 5%;
}

.who-third-container h2 {
  margin-bottom: 20px;
  padding-left: 3%;
  padding-right: 3%;
  color: #1c478f;
}

.double-quotes {
  width: 50px;
  margin-bottom: 30px;
}

.double-quotes-last {
  position: absolute;
  right: 5% !important;
  /* transform: scaleY(-1); */
  /* transform: translateX(560%); */
}

.who-eclipse {
  display: none;
}

.who-fifth-container {
  background-color: #1c478f;
  padding-top: 100px;
  padding-bottom: 80px;
  padding-left: 5%;
  padding-right: 5%;
  color: var(--white-color);
}

.who-fifth-container h1 {
  border-bottom: 2px solid var(--white-color);
  width: 320px;
  padding-bottom: 15px;
  margin-bottom: 80px;
  text-transform: uppercase;
}

.List-item {
  width: 40px;
}

.list-item-container {
  display: flex;
  gap: 20px;
}

.list-item-container h2 {
  font-size: 20px;
  margin-bottom: 10px;
  text-transform: uppercase;
  color: #b8d2ff;
}

.list-item-container p {
  margin-bottom: 50px;
}

.List-item-2 {
  transform: rotate(-90deg);
}

.List-item-3 {
  transform: rotate(-180deg);
}

.List-item-4 {
  transform: rotate(-270deg);
}

.who-sixth-container {
  background-color: #4287fc;
  padding-top: 100px;
  padding-bottom: 80px;
  padding-left: 5%;
  padding-right: 5%;
  color: #1c478f;
}

.who-sixth-container h1 {
  border-bottom: 2px solid #1c478f;
  width: 320px;
  padding-bottom: 15px;
  margin-bottom: 80px;
  text-transform: uppercase;
}

.who-seventh-container {
  background-color: #025df2;

  padding-top: 100px;
  padding-bottom: 80px;
  color: var(--white-color);
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 25px;
}

.who-eighth-container {
  background-color: #4287fc;
  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 5%;
  padding-right: 5%;
}

.who-eighth-container-h1 {
  border-bottom: 2px solid var(--white-color);
  width: 220px;
  padding-bottom: 15px;
  margin-bottom: 60px;
  text-transform: uppercase;
  color: var(--white-color);
}

.who-eighth-container-h2 {
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 50px;
  text-transform: uppercase;
  color: #1c478f;
}

.who-eighth-container-p {
  text-align: center;
  padding-bottom: 15px;
  margin-bottom: 50px;
  color: var(--white-color);
}

.who-ninth-container {
  background-color: #1c478f;
  padding-top: 20%;
  padding-bottom: 20%;
  padding-left: 5%;
  padding-right: 5%;
}

.empathy-map-last-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.empathy-map-last-container h1 {
  font-size: 28px;
}

@media only screen and (min-width: 1024px) {
  .who-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 100svh;
  }
  .who-main-container h1 {
    font-size: 60px;
  }
  .who-first-container p {
    width: 60%;
    font-size: 16px;
    line-height: 25px;
  }
  .who-pics-section {
    transform: translateY(-100px) !important;
    transform: translateX(-250px) !important;
    /* margin-bottom: 50px; */
    margin-top: -50px !important;
  }
  .who-rita-pic {
    width: fit-content;
    /* transform: translateY(-70%) !important; */
    transform: translateX(65%) !important;
    width: 300px;
    z-index: -20 !important;
  }
  .who-ana-pic {
    width: 300px;
    z-index: 5 !important;
    margin-bottom: -80px;
  }
  .linkedin-who {
    width: 30px;
  }
  .who-name-linkedin {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    padding-left: 20px;
    gap: 15px;
  }
  .who-first-container {
    margin-bottom: 0;
    margin-top: -80px;
  }
  .translucido-h1 {
    font-size: 32px;
    width: 350px;
    margin-bottom: 0;
  }
  .who-p-image-section {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
  .who-p-image-section img {
    width: 600px;
    position: relative;
    z-index: -1 !important;
    transform: translate(10%, 10%) !important;
  }
  .translucido-p-section {
    width: 80%;
    line-height: 30px;
    font-size: 16px;
    z-index: 1 !important;
  }
  .who-third-container {
    padding-bottom: 100px;
    z-index: -1 !important;
  }
  .who-third-container h2 {
    position: relative;
    font-size: 35px;
    text-align: center;
    padding-left: 5%;
    padding-right: 5%;
    padding-top: 3%;
    padding-bottom: 1%;
    z-index: 10 !important;
  }

  .double-quotes-first {
    position: absolute;
    left: 5%;
  }
  .double-quotes-last {
    position: absolute;
    right: 22%;
  }
  .who-eclipse {
    display: block;
    position: absolute;
    right: -480px;
    transform: translateY(-50%) !important;
    z-index: 0;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
  .who-fifth-container h1 {
    width: 400px;
    font-size: 32px;
    margin-bottom: 100px;
  }
  .list-item-main-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* Two columns with equal width */
    grid-template-rows: repeat(2, 1fr); /* Two rows with equal height */
    gap: 10%;
  }
  .list-item-container {
    max-width: 600px;
  }
  .list-item-container p {
    font-size: 16px;
  }
  .who-sixth-container h1 {
    font-size: 32px;
  }
  .who-seventh-container h2 {
    font-size: 24px;
    margin-bottom: 30px;
  }
  .who-seventh-container h1 {
    font-size: 40px;
    margin-bottom: 30px;
  }
  .who-eighth-container {
    padding-top: 100px;
  }
  .who-eighth-container-h1 {
    font-size: 2em;
    margin-bottom: 50px;
  }
  .who-eighth-container-h2 {
    font-size: 30px;
    margin-bottom: 50px;
  }
  .who-eighth-container-p {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 25px;
    text-align: left;
  }
  .empathy-map-container-h3 {
    font-size: 22px;
    font-weight: 500;
    margin-bottom: 50px;
  }
  .who-ninth-container {
    padding-top: 10%;
    padding-bottom: 10%;
  }
  .empathy-map-container-h2 {
    font-size: 24px;
  }
  .empathy-map-last-container h1 {
    font-size: 60px;
  }
  .empathy-map-last-container {
    display: flex;
    align-items: first baseline !important;
    justify-content: flex-start !important;
    flex-direction: row;
    gap: 20px !important;
  }
}
