.form-main-container-2 {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.form-input-label {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}

.form-main-container-2 input {
  width: 80vw;
  height: 25px;
  border-style: none;
}

.form-main-container-2 textarea {
  width: 80vw;
  height: 100px;
  border-style: none;
}

.form-input-label input,
.form-input-label textarea {
  border-radius: 0px;
  border-bottom: 2px solid var(--white-color);
  outline: none;
  padding: 5px;
  margin-bottom: 10px;
  background-color: transparent;
  caret-color: var(--white-color);
  color: var(--white-color);
  animation: blink 1.5s infinite ease;
}

.form-message-area {
  margin-bottom: 20px !important;
}

.submit-btn {
  width: 200px !important;
  height: 35px !important;
  border-radius: 50px;
  background-color: transparent;
  border: 1px solid var(--white-color) !important;
  color: var(--white-color) !important;
  font-size: 16px;
  transition: background-color 0.3s, transform 0.3s;
}

.submit-btn-active:hover {
  cursor: pointer;
  background-color: var(--white-color);
  color: var(--main-blue) !important;
}

/* Click state */
.submit-btn-active:active {
  transform: scale(0.95);
}

@keyframes blink {
  0%,
  50%,
  100% {
    caret-color: var(--white-color);
  }
  25%,
  75% {
    caret-color: transparent;
  }
}

.form-main-container-2 input,
.form-main-container-2 textarea {
  border: none;
  border-bottom: 1.5px solid var(--white-color);
  outline: none;
  padding: 5px;
  margin-bottom: 10px;
  background-color: transparent;
  caret-color: var(--white-color);
  color: var(--white-color);
  animation: blink 2s infinite ease;
}

.form-main-container-2 input.invalid,
.form-main-container-2 textarea.invalid {
  border-bottom: 1.5px solid rgb(199, 65, 65) !important;
}

.form-input {
  font-size: 16px;
}

.form-main-container-2 input,
.form-main-container-2 textarea {
  touch-action: manipulation;
  font-size: 16px;
}

.not-valid-btn {
  border: 1.5px solid rgb(152, 152, 152) !important;
  color: rgb(152, 152, 152) !important;
}

@media only screen and (min-width: 1024px) {
  .form-main-container-2 {
    display: flex;
    align-items: flex-end;
    justify-content: space-between !important;
    flex-direction: row;
    gap: 5%;
    /* height: 100svh; */
  }
  .form-main-container-2 input {
    width: 90%;
  }
  .form-input-label textarea {
    width: 90%;
  }
  .form-input-label,
  .form-input,
  textarea {
    padding-bottom: 0;
  }
  textarea:last-child {
    margin-bottom: 0 !important;
  }
  .form-principal-container {
    width: 80%;
  }
  .submit-btn {
    width: 180px !important;
  }
}
