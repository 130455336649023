.form-main-container {
  padding-top: 130px;
  padding-left: 5%;
  padding-right: 5%;
  color: var(--white-color);
  margin-bottom: 80px;
}

.form-main-container h1 {
  margin-bottom: 50px;
}

.form-rectangle {
  display: block;
  position: absolute;
  left: -500px;
  top: -5px;
  z-index: -200;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  rotate: 25deg;
}

@media only screen and (min-width: 1024px) {
  .form-media-container {
    padding-top: 50px;
    margin-bottom: 300px !important;
  }
  .form-media-container h1 {
    margin-bottom: 100px;
    font-size: 60px;
  }
  .form-component {
    padding-left: 12%;
    padding-right: 12%;
  }
  .form-rectangle {
    display: block;
    position: absolute;
    left: -280px;
    z-index: -200;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
}
