.empathy-map-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.empathy-map-section {
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 5%;
  padding-right: 5%;
  display: flex;
  align-items: center;
  justify-content: flex-end !important;
  flex-direction: column;
  background-color: var(--white-color);
  margin-bottom: 40px;
  text-align: center;
  border-radius: 10px;
  width: 90%;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.empathy-map-container img {
  margin-bottom: 30px;
}

.empathy-map-container h2 {
  color: var(--main-blue);
  margin-bottom: 10px;
}

.empathy-map-container-h3 {
  color: var(--white-color);
  margin-bottom: 10px;
  text-align: center;
  margin-bottom: 50px;
}

.empathy-map-container-h2 {
  color: var(--white-color);
  margin-bottom: 10px;
  margin-bottom: 50px;
}

.empathy-map-last-container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  color: var(--white-color);
  width: 100% !important;
}

@media only screen and (min-width: 1024px) {
  .empathy-map-container {
    display: flex;
    align-items: center;
    justify-content: space-between !important;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 50px;
  }
  .empathy-map-section {
    padding-top: 4%;
    padding-bottom: 3%;
    padding-left: 5%;
    padding-right: 5%;
    display: flex;
    align-items: center;
    justify-content: flex-start !important;
    flex-direction: column;
    background-color: var(--white-color);
    margin-bottom: 20px;
    text-align: center;
    border-radius: 10px;
    width: 30% !important;
    height: 310px;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
  .empathy-map-container img {
    width: 30%;
    margin-bottom: 30px;
  }
}
