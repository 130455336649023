.image-slider-container {
  background-image: url(../../Assets/HomePage/LandingAssets/LandingPageImages/ImagesBackground.png);
  background-size: 100% 100%;
  /* background-position: center center; */
  position: relative;
  height: 300px;
  overflow: hidden;
  margin-bottom: 45px;
  background-repeat: no-repeat;
}

.slider-and-phrase {
  display: flex;
  justify-content: center;
  flex-direction: column;
  padding-top: 150px;
  /* gap: 10px; */
}

.home-page-phrase {
  background-image: url(../../Assets/HomePage/LandingAssets/EmpathyPhrase/EmpathyBackground.png);
  background-size: 219px;
  background-position: left top;
  background-repeat: no-repeat !important;
}

.slider-and-phrase-h1 {
  color: var(--white-color);
  font-weight: 700;
  font-size: 32px;
  line-height: 60px;
  padding-left: 5%;
  padding-right: 3%;
}

.typed-phrase-h1 {
  color: var(--white-color);
  font-weight: 700;
  font-size: 28px;
  line-height: 40px;
  padding-left: 5%;
  padding-right: 3%;
  margin-bottom: 85px;
}

.scroll-down {
  display: none;
  width: 24px;
  height: 40px;
}

.rectangle-toggle {
  display: none;
}

.eclise-toggle {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .home-page-image-typed {
    display: flex;
    align-items: center !important;
    justify-content: space-around;
    flex-direction: row-reverse;
    /* padding-top: 100px; */
    margin-top: 0 !important;
    padding-left: 5%;
    padding-right: 3%;
    height: 100svh;
    /* margin-bottom: 50px; */
  }
  .slider-and-phrase {
    padding-top: 50px !important;
  }
  .slider-and-phrase-h1 {
    font-size: 60px;
    width: 380px;
    line-height: 50px;
    /* padding-top: 20px; */
  }
  .home-page-phrase-typed-main-section {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start !important;
    flex-direction: column;
    width: 50%;
    gap: 50px;
  }
  .typed-phrase-component {
    font-size: 40px;
    line-height: 80px !important;
    /* width: 600px !important; */
    height: 100%;
  }
  .typed-phrase-h1 {
    padding-left: 0 !important;
  }
  .home-page-phrase {
    background-size: 100% 100%;
    background-position: left center !important;
    background-repeat: no-repeat !important;
  }
  .image-slider-container {
    position: relative;
    height: 500px !important;
    overflow: hidden;
    background-size: 90% 90%;
  }
  .scroll-down {
    display: block;
    position: fixed !important;
    bottom: 30px !important;
    left: 30px;
    width: 40px;
    height: 65px;
    z-index: 10 !important;
  }
  .home-who-we-are-section-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    height: 80svh;
  }
  .rectangle-toggle {
    display: block;
    position: absolute;
    left: -15em;
    height: 650px;
    transform: translateY(-40em) rotate(15deg) !important;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
  .eclise-toggle {
    display: block;
    position: absolute;
    right: -35em;
    transform: translateY(-40em) rotate(30deg) !important;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
}
