.footer-main-main-container {
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

.footer-main-container {
  background-image: url(../../Assets/FooterAssets/FooterBackground/FooterBackground.png);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: 110% 100%;
  /* height: 700px; */
  width: 100%;
  position: static;
  bottom: 0;
  margin-bottom: 0;
  /* padding-bottom: 30px; */

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  z-index: 50 !important;
}

.footer-arrow {
  display: none;
}

.footer-logo-container {
  /* display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column; */
  padding-top: 80px;
  margin-bottom: 40px;
}

.footer-logo-container img {
  margin-bottom: 20px;
}

.footer-address {
  margin-bottom: 50px;
}

.footer-main-container p {
  color: var(--white-color);
  font-weight: 400;
  font-size: 10px;
  text-align: left;
}

.footer-logo {
  width: 200px;
}

.footer-mail-number-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 5px;
}

.footer-email-contacts-section,
.footer-contacts-phone-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 17.5px;
}

.email-icon {
  color: var(--white-color);
  height: 15px;
}

.footer-pages-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;
  margin-bottom: 20px;
}

.footer-pages-section h5 {
  color: var(--white-color);
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  width: 200px;
  margin-bottom: 10px;
}

.footer-pages-section p {
  color: lightgray;
  font-weight: 400;
  font-size: 11px;
  text-align: left;
  margin-bottom: 8px;
}

.footer-pages {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 30px;
}

.social-media-icons {
  display: flex;
  gap: 10px;
}

.footer-icon {
  width: 40px;
  padding-bottom: 50px;
  cursor: pointer;
  transition: filter 0.3s, transform 0.3s;
}

.footer-icon:hover {
  filter: brightness(100);
  transform: scale(1.02);
}

/* .footer-icon:hover {
  color: var(--main-blue);
  border: var(--main-blue) !important;
}

.footer-icon:active {
  transform: scale(0.95);
} */

.linkedInNuno {
  background-color: #025df2;
  text-align: center;
}

.linkedInNuno h5 {
  font-size: 7px;
  color: var(--white-color);
  font-weight: 100;
  padding-bottom: 10px;
  cursor: pointer;
}

.social-media-icons {
  background-color: #025df2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.contacts {
  cursor: pointer;
}

@media screen and (max-width: 1020px) {
  .footer-arrow {
    display: none;
  }
}

@media screen and (min-width: 1024px) {
  .footer-arrow {
    display: block;
    width: 90%;
    height: 90%;
    cursor: pointer;
  }
  .footer-logo-container img {
    width: 80% !important;
  }
  .footer-main-container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
    padding: 5% 5%;
    gap: 3%;
    padding-bottom: 50px;
  }
  .footer-mail-number-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .footer-pages-section {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row;
    padding-top: 100px;
  }
  .footer-pages-section h5 {
    width: 140px;
  }
  .footer-pages h5 {
    text-align: left;
    margin-bottom: 20px;
  }
  .footer-pages {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 0px;
  }
  .footer-pages p {
    margin-bottom: 15px;
    font-size: 12px;
  }
  .footer-icon {
    padding-bottom: 20px;
  }
}
