.cards-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 32px;
  height: fit-content;
  margin-bottom: 50px;
}

.card-container {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 297px;
  background-color: white;
  height: fit-content;
  padding: 25px;
  border-radius: 3px;
  filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.2));

  /* gap: 50px; */
}

.card-container h3 {
  width: fit-content;
  height: fit-content;
  color: #202020;
  font-weight: 700;
  font-size: 18px;
  line-height: 14px;
  margin-bottom: 18px;
}

.card-container h5 {
  width: fit-content;
  height: 12px;
  font-weight: 600;
  font-size: 11px;
  line-height: 18px;
  margin-bottom: 50px;
  color: #787878;
}

.card-container p {
  width: fit-content;
  /* height: 17px; */
  font-size: 13px;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #555555;
  height: fit-content;
  margin-bottom: 30px;
}

.icon {
  background-image: url(../../../../Assets/HomePage/SolutionsAssets/SolutionsIconsBackground.png);
  width: 40px;
  height: 40px;
  padding: 15%;
  margin-bottom: 25px;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
}

@media only screen and (min-width: 1024px) {
  .cards-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    gap: 100px;
    height: fit-content;
    margin-bottom: 50px;
  }
  .card-container {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    /* gap: 25px; */
    width: 400px;
    background-color: white;
    height: 380px;
    padding: 40px;
    border-radius: 5px;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
  .card-container h3 {
    width: fit-content;
    height: fit-content;
    color: #202020;
    font-weight: 700;
    font-size: 24px;
    line-height: 14px;
    margin-bottom: 25px;
  }

  .card-container h5 {
    width: fit-content;
    height: 12px;
    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 50px;
    color: #787878;
  }

  .card-container p {
    width: fit-content;
    /* height: 17px; */
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: left;
    color: #555555;
    height: fit-content;
    margin-bottom: 30px;
  }
  .wide-card {
    width: 65%;
  }
  .icon {
    width: 60px;
    height: 60px;
    padding: 15%;
    margin-bottom: 25px;
    filter: drop-shadow(4px 4px 8px rgba(0, 0, 0, 0.2));
  }
}
