.navbar-menu-logo-container {
  height: 95px;
  margin-bottom: 50px;
  /* z-index: 99999999 !important; */
  /* background-color: red; */
  position: fixed;
  z-index: 2;
}

.logo {
  position: fixed;
  top: 30px;
  left: 36px;
  z-index: -1;
  width: 40px;
  height: 41px;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: white;
}

/* Color/shape of burger icon bars on hover */
/* .bm-burger-bars-hover {
    background: #a90000;
  } */

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
  right: 20px !important;
  top: 20px !important;
  background: transparent;
}

/* Color/shape of close button cross */
/* .bm-cross {
  background: white;
} */

/*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */

.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #055df2;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #055df2;
}

/* Wrapper for item list */
.bm-item-list {
  background-color: #055df2;
  padding: 0.8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 41px;

  font-family: Poppins;
  font-size: 22px;
  font-weight: 500;
  line-height: 48px;
  letter-spacing: 0em;
  text-align: center;
}

/* Individual item */
.bm-item {
  display: inline-block;
  background-color: #055df2;

  color: white;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  transition: color 0.2s, transform 0.3s;
}

.bm-item:hover {
  color: #23478f;
  transform: scale(1.02);
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

.navbar-menu-logo-container {
  background-color: transparent;
  transition: background-color 0.5s ease-in;
}

.navbar-menu-logo-container.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: rgba(5, 93, 242, 0.9);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  /* filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2)); */
}

.active-link {
  color: #0c1934;
  font-weight: 700;
}
