html,
body {
  position: relative;
  overflow-x: hidden;
}

.page-main-container {
  background-color: #4287fc;
}

.solutions-main-container-2 {
  padding-top: 130px;
  height: 95svh;
  margin-bottom: 0;
  background-color: #4287fc;
  z-index: 0;
  padding-bottom: 30px;
}

.solutions-eclipse,
.solutions-eclipse-2 {
  display: none;
}

.solutions-h1 {
  border: none !important;
  color: var(--white-color) !important;
  font-weight: 700 !important;
  font-size: 40px !important;
  margin-bottom: 30px;
}

.solutions-center-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 30px;
}

.solutions-cartoon {
  width: 280px;
  height: auto;
  margin-bottom: 50px;
}

.solutions-btn {
  width: auto;
  height: auto;
  font-family: Poppins;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 14px;
  padding-bottom: 14px;

  border-radius: 50px;
  font-size: 14px;
  border: 2px solid var(--white-color);
  background-color: transparent;
  color: var(--white-color);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

/* Hover state */

.solutions-btn:hover {
  background-color: var(--white-color);
  color: var(--main-blue);
}

/* Click state */

.solutions-btn:active {
  transform: scale(0.95);
}

.solutions-second-container {
  background-image: url(../../Assets/SolutionsPage/SolutionsBackgrounds/SolutionsFirstBackground.png) !important;
  background-size: 100% 100% !important;
  filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));

  padding-top: 90px;
  padding-bottom: 90px;
  padding-left: 5%;
  padding-right: 5%;
}

.solutions-second-container p {
  color: var(--white-color);
  margin-bottom: 20px;
}

.solutions-third-container {
  background-image: url(../../Assets/SolutionsPage/SolutionsBackgrounds/SolutionsSecondBackground.png) !important;
  background-size: 100% 100% !important;
  /* filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2)); */

  padding-top: 80px;
  padding-bottom: 80px;
  padding-left: 5%;
  padding-right: 5%;

  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  /* gap: 50px; */
}

.solutions-third-container p {
  color: var(--white-color);
  margin-bottom: 20px;
}

.solutions-images {
  width: 100%;
  margin-bottom: 50px;
  border-radius: 10px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
}

.solutions-third-container h1 {
  border-bottom: 2px solid var(--white-color);
  width: 250px;
  color: var(--white-color);
  padding-bottom: 15px;
  margin-bottom: 40px;
}

.solutions-forth-container {
  background-size: 100% 100% !important;
  padding-top: 100px;
  padding-bottom: 80px;
  padding-left: 5%;
  padding-right: 5%;
  background-color: #1c478f;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
}

.solutions-forth-container h1 {
  border-bottom: 2px solid var(--white-color);
  width: 250px;
  color: var(--white-color);
  padding-bottom: 15px;
  margin-bottom: 40px;
}

.solutions-forth-container p {
  color: var(--white-color);
  margin-bottom: 20px;
}

@media only screen and (min-width: 1024px) {
  .solutions-h1 {
    font-size: 60px !important;
  }
  .solutions-cartoon {
    width: 500px;
    margin-bottom: 50px;
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
  .solutions-eclipse {
    display: block;
    position: absolute;
    left: -650px;
    top: -30px;
    z-index: 0;
    /* box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); */
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }

  .solutions-eclipse-2 {
    display: block;
    position: absolute;
    right: -680px;
    top: -30px;
    z-index: 0;
    transform: scaleX(-1);
    filter: drop-shadow(2px 2px 5px rgba(0, 0, 0, 0.2));
  }
  .solutions-center-container {
    height: 80svh;
    padding-bottom: 50px;
  }
  .solutions-second-container {
    padding-top: 8%;
    padding-bottom: 8%;
  }
  .solutions-second-container p {
    font-size: 18px;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
  .solutions-third-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: row-reverse;
  }
  .solutions-third-container-left {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
  }
  .solutions-third-container-left h1 {
    font-size: 30px;
  }
  .solutions-third-container-left p {
    width: 70%;
    font-size: 16px;
  }
  .solutions-third-container img {
    width: auto;
    height: auto;
  }
  .solutions-forth-container {
    display: flex;
    align-items: center !important;
    justify-content: flex-end !important;
    flex-direction: row;
    gap: 15%;
  }
  .solutions-forth-container img {
    width: auto;
    height: auto;
  }
  .solutions-forth-container-left {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    flex-direction: column;
  }
  .solutions-forth-container-left h1 {
    text-align: right;
    font-size: 30px;
  }
  .solutions-forth-container-left p {
    width: 100%;
    font-size: 16px;
    text-align: right;
  }
}
