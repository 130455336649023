.who-we-are-main-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 50px;
}

.who-we-are-main-section h1 {
  color: var(--white-color);
  font-weight: 700;
  font-size: 26px;

  padding-bottom: 15px;
  border-bottom: 2px solid var(--white-color);
  width: 280px;
  text-align: center;
  text-transform: uppercase;
}

.who-we-are-main-section p {
  font-weight: 400;
  font-size: 15px;
  text-align: center;
  color: var(--white-color);
  line-height: 22px;
  width: 332px;
}

.who-we-are-btns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 105px;
  gap: 15px;
}

.who-we-are-btn-left {
  width: 136px;
  height: 48.6px;
  padding: 16px, 40px, 16px, 40px;
  border-radius: 50px;
  border: 3px solid var(--white-color);
  gap: 10px;
  background-color: transparent;
  color: var(--white-color);
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.who-we-are-btn-right {
  width: 151px;
  height: 48.6px;
  padding: 16px, 40px, 16px, 40px;
  border-radius: 50px;
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  background-color: var(--white-color);
  color: var(--main-blue);
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border: none;
}

/* Hover state */

.who-we-are-btn-left:hover {
  background-color: var(--white-color);
  color: var(--main-blue);
}

.who-we-are-btn-right:hover {
  background-color: var(--main-blue);
  color: var(--white-color);
  border: 3px solid var(--white-color);
}

/* Click state */

.who-we-are-btn-left:active,
.who-we-are-btn-right:active {
  transform: scale(0.95);
}

@media only screen and (min-width: 1024px) {
  .who-we-are-btns {
    padding-bottom: 20px;
  }
  .who-we-are-main-section h1 {
    font-size: 32px !important;
    margin-bottom: 20px;
    width: 320px;
  }
  .who-we-are-main-section p {
    font-size: 20px !important;
    width: 800px;
    line-height: 30px;
    margin-bottom: 20px;
  }
  .who-we-are-btn-left {
    font-size: 16px;
    width: 150px;
  }
  .who-we-are-btn-right {
    font-size: 16px;
    width: 200px;
  }
}
